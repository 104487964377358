@import "src/Variables";
@import "components/Courses/Course/Components/ContentBlock/Components/Text/TextEditor.css";

body {
  margin: 0;
  color: white;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  transition: padding-right 0.2s linear;
  transition: overflow-y 0.2s linear;
}

* {
  font-family: Golos, sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;

  .compact-picker {
    zoom: 1.6;
    max-width: 360px;
    width: 100% !important;
  }
}

ol,
ul {
  br {
    display: block;
    margin: 0;
    line-height: 0px;
    content: " ";
  }
}

a {
  color: $link;
  text-decoration: none;
}

@font-face {
  font-family: "Golos";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Golos-Text_Regular.woff2") format("woff2"),
    url("../fonts/Golos-Text_Regular.woff") format("woff");
  /* woff2 for  Chrome 26+, Opera 23+, Firefox 39+ */
  /* woff for Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Golos";
  font-style: normal;
  font-weight: 500;

  src: url("../fonts/GolosText-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Golos";
  font-style: normal;
  font-weight: 600;

  src: url("../fonts/GolosText-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Golos";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/Golos-Text_Regular.woff2") format("woff2"),
    url("../fonts/GolosText-Bold.woff") format("woff");
  src: url("../fonts/GolosText-Bold.ttf") format("truetype");
}

@for $i from 1 through 5 {
  h#{$i} {
    font-weight: 400;
    color: $title;
  }
}

h1 {
  font-size: 70px;
  line-height: 110%;
}

h2 {
  font-size: 50px;
  line-height: 130%;
}

h3 {
  font-size: 38px;
  line-height: 110%;
}

h4 {
  font-size: 28px;
  line-height: 140%;
}

h5 {
  font-size: 20px;
  line-height: 140%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page {
  background-color: $title;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    display: block;
  }
}

.page.preview,
.page.main,
.page.index {
  background-color: #fff;
  display: block;
}

.new-bg {
  background: #f8f3f4;
  background-image: url("./image/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top 100vh;

  .container {
    padding: 0 16px;
    max-width: calc(1280px + 32px);
  }
}

.container {
  max-width: 1312px;
  margin: 0 auto;
  min-height: calc(100vh - 281px);
  width: 100%;
  overflow: hidden;
}

.main {
  display: block;
}

.page.index .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.container.preview {
  width: 100%;
  position: relative;
  padding: 0px 80px;
  @media (max-width: 1440px) {
    padding: 0px 60px;
  }
  @media (max-width: 1024px) {
    padding: 0px 40px;
  }
  @media (max-width: 768px) {
    padding: 0px 8px;
  }
}

.page.auth {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.page.page404,
.page.page404 .pageCenter,
.page.page404 .container {
  width: 100vw;
  height: 100vh;
}

.page.auth .pageCenter,
.page.auth .container {
  width: 100%;
  @media (max-width: 1024px) {
    height: 100%;
  }
}

.container.auth {
  @media (max-width: 1024px) {
    padding: 32px 0px;
  }
}

.pageCenter {
  width: 100%;

  // TODO delete after normalize button for landing
  .app-button {
    min-height: 50px;
  }
}

.mainWrapper {
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.pageContainer {
  background: #f3f5f6;
  width: 100%;
  color: $black;
  border-radius: 30px 0 0 30px;
  min-height: calc(100vh - 5px);
  padding: 30px 40px;
  overflow: hidden;
  z-index: 2;
  position: relative;

  @media (max-width: 1440px) {
    padding: 24px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px 30px 0 0;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }

  &.navMinimized {
    @media (max-width: 1024px) {
      padding: 24px 8px 0;
    }
  }
}

.pageLoader {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.gradient {
  position: absolute;
  top: 0px;
  right: 0px;
  pointer-events: none;
}

.iti {
  width: 100%;
}

.iti__country-list {
  top: 62px;
  color: #000;
  width: 100%;
  left: 1px;
}

.src-components-WelcomeModal-WelcomeModal-module__form {
  .iti__country-list {
    height: 120px;
  }
}

.iti__country {
  flex-wrap: wrap;
}

.iti--container {
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  z-index: 1060;
  padding: 1px;
}

button {
  background-color: transparent;
  border: none;
}

@import "src/Variables";
.topMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 100px;
  user-select: none;
  background-color: rgba(black, 0.85);
  width: 100%;
  height: 100vh;
  position: relative;
  opacity: 1;

  @media (max-width: 768px) {
    padding-right: 0;
  }
  .link {
    font-size: 20px;
    color: $white;
    border: 1px solid transparent;
    border-radius: 58px;
    padding: 14px 40px;
    text-decoration: none;
    &:hover {
      opacity: 0.6;
    }
  }
}

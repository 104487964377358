@import "src/Variables";
.container {
  position: relative;
  min-height: 281px;
  overflow: hidden;
  background-color: $title;
}

.inner {
  display: flex;
  padding: 80px 160px 50px 80px;
  position: relative;
  gap: 60px;
  max-width: 1440px;

  margin: 0px auto;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 65px 65px 50px 40px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 32px;
    padding: 50px 16px;
  }
}

.columnWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 64px;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }

  @media (max-width: 500px) {
    gap: 10px;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 18px;
  line-height: 25px;
  color: #9998a1;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.item {
  font-size: 16px;
  line-height: 22px;
  color: $white;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 18px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.columnWithLogo {
  @media (max-width: 850px) {
    flex-direction: row;
    gap: 50px;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 16px;
  }
}

.footerInfoTitle {
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 8px;
  display: block;
}

.footerInfoSubtitle {
  color: #9998a1;

  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 40px;

  @media (max-width: 850px) {
    margin-bottom: 0px;
  }
}

.gridRepeat {
}

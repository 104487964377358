@import "src/Variables";
.title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 32px;
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputsWrapper {
  display: grid;
  gap: 16px;

  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main {
  max-height: initial;
}

.modalFitWelcome {
  padding-bottom: 0px;

  & > div {
    margin-bottom: 0px;
  }
}

.dropdownContainer {
  position: relative;
}

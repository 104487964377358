@import "src/Variables";
.menuContainer {
  position: fixed;
  background-color: #121212;
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 3;
  filter: alpha(opacity=10);
}

.contentInner {
  width: 100%;
  height: calc(100vh - 70px);
  margin: 0 auto;
  position: fixed;
  background-color: rgba($color: #121212, $alpha: 1);
  background-color: transparent;
  z-index: 2;
  overflow: auto;

  @media (max-width: 1024px) {
    padding-right: 48px;
  }
}
.contentWrapper {
  position: relative;
  top: -100%;

  width: 100%;
  height: calc(100vh - 70px);
  margin: 0 auto;
  margin-top: 70px;
  position: fixed;
  z-index: 2;
  background-color: rgba($color: #121212, $alpha: 0.95);
  transition: all 0.25s ease-in;
}

.contentShow {
  top: 0;
}

.middleBorder {
  border-bottom: 2px solid white;
  display: block;
  width: 200px;
  height: 2px;
  margin-left: auto;
}
.otherButtons {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 16px;
}

.bottomButton {
  font-size: 18px;
  text-decoration: none;
  color: inherit;
}

.bottomButton + .bottomButton {
  padding-top: 16px;
}

@import "src/Variables";
.wrapper {
  position: relative;
}

.inner {
  display: flex;
}

.textAreaWrapper {
  width: 100%;
  padding-top: 29px;
  border: 1px solid #e3e3e3;
  border-radius: 16px;

  &.error {
    border: 1px solid $error;
  }
}

.input {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding: 18px 20px;
  outline: none;
  font-size: 16px;
  line-height: 150%;
  color: #1e1c24;
  border: 1px solid #e3e3e3;
  max-height: 60px;
  height: 60px;

  transition: all 0.3s;
  transition-property: border, padding;

  @media (max-width: 768px) {
    padding: 29px 80px 7px 20px;
  }

  &.textarea {
    min-height: 100px;
    max-height: unset;
    height: auto;
    width: 100% !important;
    border: 0;
  }

  &.focused {
    @extend .input;
    padding: 29px 20px 7px 20px;

    &.textarea {
      padding: 0 20px 7px 20px;
      border: 0;
    }

    &.disabled {
      border: 1px solid #e3e3e3;
    }
  }

  &:hover {
    border: 1px solid $text;

    &.disabled {
      border: 1px solid #e3e3e3;
    }

    &.textarea {
      border: 0;
    }
  }

  &.error {
    @extend .input;
    border: 1px solid $error;
  }

  &.withLeftIcon {
    padding: 29px 20px 7px 56px;
  }

  &.withRightIcon {
    padding: 29px 56px 7px 20px;
  }

  &.withRightIcon {
    &.withLeftIcon {
      padding: 29px 56px 7px 56px;
    }
  }

  &.withoutTitle {
    padding: 7px 20px 7px 20px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.withDoubleIcon {
    padding: 29px 130px 7px 20px;
  }
}

.errorField {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: $error;
}

.errorText {
  position: absolute;
  top: 64px;
  left: 21px;
  font-size: 10px;
  color: $error;

  &.bottomError {
    top: initial;
    position: relative;
  }
}

.title {
  position: absolute;
  top: 18px;
  left: 20px;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
  z-index: 1;
  font-size: 16px;
  line-height: 150%;
  color: #4a4852;
  opacity: 0.5;

  transition: all 0.1s;

  &.focused {
    @extend .title;
    top: 8px;
  }

  &.error {
    color: $error;
  }

  &.withLeftIcon {
    left: 56px;
  }
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &.disabled {
    cursor: auto;
  }

  &.left {
    left: 18px;
  }

  &.right {
    right: 18px;
  }

  &.second {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 2px);

    width: 60px;
    border-right: 1px solid #e7e7f1;
    border-left: 1px solid #e7e7f1;
    right: 58px;
    background-color: #8bdbb022;

    svg {
      stroke: #8bdbb0;
    }

    &.correct {
      background-color: #8bdbb0;

      svg {
        stroke: white;
      }
    }
  }

  &.passwordIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.descriptionCoursesTitle {
  padding: 10px;
}

.small {
  svg {
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
}

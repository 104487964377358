@import "src/Variables";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: $title;
  cursor: pointer;
  line-height: 1;
  line-height: 140%;
  text-decoration: none;
  overflow: hidden;
  text-align: center;

  transition: all 0.3s;

  background-color: $main;

  &:hover {
    background-color: $mainHover;
  }

  &:disabled {
    background-color: $disabled;
    pointer-events: none;
    color: rgba(74, 72, 82, 0.5);
  }

  &.outlined {
    padding: 8px 24px;
    background-color: transparent;
    border: 1px solid #1e1c24;

    &:hover {
      border-color: $mainHover;
      background-color: $mainHover;
    }

    &:disabled {
      border-color: $disabled;
    }
  }

  &.flat {
    background-color: transparent;
    &:hover {
      background-color: $grey;
    }
  }

  &.error {
    background-color: transparent;
    border: 1px solid $error;
    color: $error;

    &:hover {
      color: white;
      background-color: $error;
    }
    &:disabled {
      border-color: $disabled;
    }
  }
}

.loader {
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $main;

  svg {
    :global {
      animation: 0.7s infinite linear rotate;
    }
  }
}

$white: #ffffff;
$black: #000000;
$main: #ffdd2d;
$mainHover: #f6c244;
$error: #eb7d7d;
$success: #8bdbb0;
$grey: #f3f5f6;
$lightGrey: #f8f8fb;
$disabled: #e7e7f1;
$text: #4a4852;
$title: #1e1c24;
$lightTitle: #9199a3;
$link: #6489e9;
$linkHover: #3365e0;

@mixin textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@import "src/Variables";
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  width: 320px;
  z-index: 1;

  transition: all 0.3s;

  @media (max-width: 1024px) {
    display: none;
  }

  &.isHiding {
    width: 120px;
  }

  &:hover .toggleArrow {
    opacity: 1;
    visibility: visible;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  min-height: 55px;

  .logo {
    min-width: 25px;
    min-height: 25px;
  }

  svg {
    fill: white;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
}

.toggleArrow {
  position: absolute;
  cursor: pointer;
  z-index: 4;
  right: 25px;
  top: 270px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.right {
    transform: rotate(180deg);
    top: 240px;
  }
}

@import "src/Variables";
.wrapper {
  position: relative;
}

.inner {
  display: flex;
}

.input {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding: 18px 20px;
  outline: none;
  font-size: 16px;
  line-height: 150%;
  color: #1e1c24;
  border: 1px solid #e3e3e3;
  max-height: 60px;
  height: 60px;

  transition: all 0.3s;
  transition-property: border, padding;

  &.focused {
    padding: 29px 20px 7px 20px;
  }

  &:hover {
    border: 1px solid $text;

    &.disabled {
      border: 1px solid #e3e3e3;
    }

    &.textarea {
      border: 0;
    }
  }

  &.error {
    border: 1px solid $error;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.errorInfo {
  margin-top: 4px;
  color: $error;
}

@import "src/Variables";
.container {
  position: relative;
  padding: 12px 0px 12px 12px;

  @media (max-width: 768px) {
    padding: 12px 0px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding-right: 12px;

  @media (max-width: 768px) {
    padding-right: 6px;
  }

  .title {
    font-size: 24px;
  }

  .closeIcon {
    cursor: pointer;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
  width: 100%;
  padding-right: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
    align-items: initial;
    padding-right: 6px;
  }
}

.center {
  justify-content: center;
}

.button {
  height: 50px;
  line-height: 1;
}

.cancleButton {
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.main {
  position: relative;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 279px);
  padding-right: 12px;

  @media (max-width: 768px) {
    max-height: calc(100vh - 335px);
    padding-right: 6px;
  }
}

.withTabs {
  max-height: calc(100vh - 328px);

  @media (max-width: 768px) {
    max-height: calc(100vh - 384px);
  }
}

.main::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.main::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.main::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgb(223, 214, 214);
}

.modalContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  background-color: rgba($color: #000000, $alpha: 0);
  padding: 32px 8px;
  transition: all 0.3s ease;

  &.visible {
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
}

.modal {
  position: absolute;
  z-index: 9999;
  top: 120px;
  left: 120px;
  right: 120px;
  bottom: 120px;
  border: 1px solid #ccc;
  background: white;
  border-radius: 32px;
  overflow: auto;
  outline: none;
  padding: 20px;
  color: $black;

  @media (max-width: 1024px) {
    top: 90px;
    left: 90px;
    right: 90px;
    bottom: 90px;
  }

  @media (max-width: 768px) {
    left: 50px;
    right: 50px;
    bottom: 50px;
  }
}

.modalFit {
  position: relative;
  width: 100%;
  max-height: 100%;
  max-width: 650px;
  margin: 0 auto;
  z-index: 9999;
  border: 1px solid #ccc;
  background: white;
  border-radius: 16px;
  overflow: auto;
  outline: none;
  padding: 20px;
  color: $black;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.disabledPadding {
  padding: 0;
}

@import "src/Variables";
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  font-size: 24px;
  min-height: 134px;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
}

.image {
  @media (max-width: 1024px) {
    max-width: 55px;
    height: 55px;
  }

  @media (max-width: 768px) {
    position: relative;
    max-width: 45px;
    max-height: 45px;
  }
}

.profileName {
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}

.emptyImage {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  svg {
    width: 48px;
    height: 48px;
  }
}

.smallSvg {
  width: 36px !important;
  height: 36px !important;
}

@import "src/Variables";
.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 500;
}

.modalFitWelcome {
  padding-bottom: 0px;
  max-width: 500px;

  & > div {
    margin-bottom: 0px;
  }
}

.main {
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dropdownContainer {
  position: relative;
}

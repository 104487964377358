@import "src/Variables";
.container {
  height: auto;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0;
  opacity: 0.5;
  cursor: pointer;

  @media (max-width: 1024px) {
    justify-content: flex-end;
  }

  &.item:hover,
  &.active {
    opacity: 1;
  }
}

.name {
  margin-left: 19px;
}

.iconContainer {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileHidden {
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
}

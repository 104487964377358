@import "src/Variables";
.hamburger {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  overflow: hidden;
  padding: 0;
  width: 38px;
  height: 38px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  background-color: rgba(255, 255, 255, -1.5);
  z-index: 5;
  opacity: 1;

  margin-left: 24px;
  @media (max-width: 768px) {
    margin-left: 16px;
  }
}

.hamburger:focus {
  outline: none;
}

.hamburgerMainRow {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 5px;
  height: 5px;
  border-radius: 3px;
  background: white;
  opacity: 1;

  &.dark {
    background-color: black;

    &.hamburgerMainRow::after,
    &.hamburgerMainRow::before {
      background-color: #121212;
    }
  }
}

.hamburgerMainRow::after,
.hamburgerMainRow::before {
  position: absolute;
  display: block;
  left: 0;
  border-radius: 3px;
  width: 100%;
  height: 5px;
  background-color: #fff;
  content: "";
  transition-delay: 0s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  opacity: 1;
}
.hamburgerMainRow::after {
  bottom: -12px;
}
.hamburgerMainRow::before {
  top: -12px;
}

//active button

.hamburgerMainRowActive {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 5px;
  height: 5px;
  border-radius: 3px;
  background: transparent !important;

  &.dark {
    background-color: black;

    &.hamburgerMainRowActive::after,
    &.hamburgerMainRowActive::before {
      background-color: #121212;
    }
  }
}
.hamburgerMainRowActive::after,
.hamburgerMainRowActive::before {
  position: absolute;
  display: block;
  left: 0;
  border-radius: 3px;
  width: 100%;
  height: 5px;
  background-color: #fff;
  content: "";
  transition-delay: 0s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  opacity: 1;
}
.hamburgerMainRowActive::after {
  bottom: 0;
  transform: rotate(-45deg);
}
.hamburgerMainRowActive::before {
  top: 0;
  transform: rotate(45deg);
}
//header
.headerWrapper {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  left: 0px;
  border-bottom: 1px solid #e7e7f1;
  overflow: hidden;
}

.noBorder {
  border-bottom: none;
}

.whiteRow {
  background: black;

  &::after,
  &::before {
    background-color: black;
  }
}

.bgDark {
  background-color: #1e1c24;
}

.bgLight {
  background-color: #f8f8fb;
}

.logoContainer {
  img {
    @media (max-width: 768px) {
      width: 32px;
      height: 35px;
    }
  }

  svg {
    fill: #1e1c24;
  }
}
.headerBlock {
  display: flex;
  align-items: center;

  .loginButton {
    @media (max-width: 500px) {
      display: none;
    }
  }

  .loginButtonWithIcon {
    display: none;

    @media (max-width: 500px) {
      display: flex;
    }
  }
}

// content
.menuContainer {
  position: fixed;
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 3;
  left: 0px;
  filter: alpha(opacity=10);
}

.contentInner {
  width: 100%;
  height: calc(100vh - 120px);

  margin: 0 auto;
  position: fixed;
  background-color: rgba($color: #1e1c24, $alpha: 1);
  background-color: transparent;
  z-index: 2;
  @media (max-width: 768px) {
    height: calc(100vh - 80px);
  }
}
.contentWrapper {
  position: relative;
  top: -100%;

  width: 100%;
  height: calc(100vh - 120px);
  padding: 15px;
  margin: 0 auto;
  margin-top: 120px;
  position: fixed;
  z-index: 2;
  background-color: rgba($color: #121212, $alpha: 0.65);
  transition: all 0.25s ease-in;

  @media (max-width: 768px) {
    height: calc(100vh - 80px);
    margin-top: 80px;
  }
}
.contentWrapperShow {
  position: relative;
  top: 0;

  width: 100%;
  height: calc(100vh - 120px);
  padding: 15px;
  margin: 0 auto;
  margin-top: 120px;
  position: fixed;
  z-index: 2;
  background-color: rgba($color: #121212, $alpha: 0.65);
  transition: all 0.25s ease-in;

  @media (max-width: 768px) {
    height: calc(100vh - 80px);
    margin-top: 80px;
  }
}
.middleBorder {
  border-bottom: 2px solid white;
  display: block;
  width: 200px;
  height: 2px;
  margin-left: auto;
  margin-right: 80px;
  @media (max-width: 768px) {
    margin-right: 60px;
  }
}
.otherButtons {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 80px;
  padding-top: 16px;
  @media (max-width: 768px) {
    padding-right: 60px;
  }
}

.bottomButton {
  font-size: 18px;
  text-decoration: none;
  color: inherit;
}

.bottomButton + .bottomButton {
  padding-top: 16px;
}

.gradient {
  position: absolute;
  top: -10px;
  right: -8px;
}
@import "src/Variables";
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;

  &.small {
    width: 40px;
    height: 40px;
  }

  &.small::after {
    width: 32px;
    height: 32px;
    margin: 4px;
  }
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $main;
  border-color: $main transparent $main transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.center {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

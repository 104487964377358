@import "src/Variables";
.wrapper {
  position: relative;
}

.inner {
  display: flex;
}

.errorInfo {
  margin-top: 4px;
  color: $error;
}

.dropdownContainer {
  position: relative;
  flex: 1 1 0px;
}

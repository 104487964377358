@import "src/Variables";
.menu {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  @media (max-width: 1024px) {
    padding: 0;
  }

  border-bottom: 1px solid #e7e7f1;
}

.imageBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  path {
    fill: black;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.burger {
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
}

.topMenu {
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
}

.index-limiter {
  padding: 0 16px;
  margin: 0 auto;
  max-width: 1312px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-absolute {
  position: absolute;
  z-index: 22;
}

@import "src/Variables";
.topMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  position: relative;
  z-index: 2;

  @media (max-width: 1024px) {
    display: none;
  }

  .link {
    color: $text;
    font-size: 18px;
    line-height: 25px;
    padding: 6px 0px;
    text-decoration: none;
    margin-right: 40px;

    &:hover {
      opacity: 0.6;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    @media (max-width: 1440px) {
      margin-right: 24px;
    }
  }
}

.tox-toolbar__primary {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05) !important;
  opacity: 0.7 !important;
}

.tox {
  border-radius: 10px !important;
}

/* For iframe modal to make textarea resizable */
.tox .tox-form__group--stretched {
  display: block !important;
}

.tox-dialog__body .tox-textarea {
  resize: vertical !important;
  min-height: 120px;
  height: 120px;
}

.tox .tox-toolbar--scrolling {
  flex-wrap: wrap !important;
}